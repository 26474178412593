import { Alert, Button, Container, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { ILoginResponse } from "../interfaces";
import useApi from "../hooks/useApi";
import Swal from "sweetalert2";
import { useCallback, useEffect, useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);

  const [params] = useSearchParams();

  const api = useApi();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();

  const { formState, register, handleSubmit, setError, setValue, getValues } =
    useForm({
      defaultValues: {
        nova_senha_token: "",
        nova_senha: "",
        nova_senha_confirmation: "",
        recaptcha_token: "",
      },
    });

  const loadRecaptcha = useCallback(async () => {
    if (executeRecaptcha) {
      executeRecaptcha("profile_activation").then((token) =>
        setValue("recaptcha_token", token),
      );
    }
  }, [executeRecaptcha, setValue]);

  const handleSuccess = useCallback(
    (response: ILoginResponse) => {
      Swal.close();

      navigate(response.redirectTo);
    },
    [navigate],
  );

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data).forEach(([field, messages]) => {
        if (field === "recaptcha_token") {
          loadRecaptcha();
        }

        setError(field as any, { type: "manual", message: messages[0] });
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [loadRecaptcha, setError],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos ativando seu cadastro. Por favor, aguarde um momento.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      api.activate(data).then(handleSuccess).catch(handleError);
    },
    [api, handleError, handleSuccess],
  );

  useEffect(() => {
    register("nova_senha_token", {
      required: "É necessário um token válido para ativação de cadastro",
    });

    setValue("nova_senha_token", `${params.get("h") ?? ""}`);
  }, [params, register, setValue]);

  useEffect(() => {
    Swal.fire({
      title: "Aguarde...",
      text: "Estamos verificando a ativação do seu cadastro.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    api
      .activationCheck({ token: params.get("h") })
      .then(() => {
        Swal.close();
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          confirmButtonText: "Voltar para o início",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          html: error?.message ?? "O token de ativação informado é inválido.",
        }).then(() => {
          navigate("/");
        });
      });
  }, [api, navigate, params]);

  useEffect(() => {
    register("recaptcha_token", {
      required:
        "O Google reCAPTCHA não foi carregado corretamente. Atualize a página e tente novamente.",
    });

    loadRecaptcha();
  }, [register, loadRecaptcha]);

  return (
    <section className="margin-section">
      <Container>
        <h1 className="text-center mb-5">ATIVAR CADASTRO</h1>

        <Form
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          style={{ maxWidth: 400 }}
          className="mx-auto"
        >
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Nova senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Informe uma nova senha"
              {...register("nova_senha", {
                required: "Este campo é obrigatório",
                minLength: 8,
              })}
              isInvalid={!!formState.errors.nova_senha}
            />
            <Form.Control.Feedback type="invalid">
              {formState.errors.nova_senha?.type === "minLength"
                ? "A senha deve ter no mínimo 8 caracteres"
                : formState.errors.nova_senha?.message ||
                  "Este campo é obrigatório"}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Confirmação de senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirme a sua nova senha"
              {...register("nova_senha_confirmation", {
                required: "Este campo é obrigatório",
                minLength: 8,
                validate: (x) => {
                  return (
                    getValues("nova_senha") === x || "As senhas não coincidem"
                  );
                },
              })}
              isInvalid={!!formState.errors.nova_senha_confirmation}
            />
            <Form.Control.Feedback type="invalid">
              {formState.errors.nova_senha_confirmation?.type === "minLength"
                ? "A senha deve ter no mínimo 8 caracteres"
                : formState.errors.nova_senha_confirmation?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {formState.errors.nova_senha_token && (
            <Alert variant="danger" className="mb-3">
              {formState.errors.nova_senha_token.message}
            </Alert>
          )}

          {formState.errors.recaptcha_token && (
            <Alert variant="danger" className="mb-3">
              {formState.errors.recaptcha_token.message}
            </Alert>
          )}

          <div className="d-flex">
            <Button
              variant="primary"
              type="submit"
              className="button mt-3 mx-auto"
            >
              <span>Enviar</span>
            </Button>
          </div>
        </Form>
      </Container>
    </section>
  );
}
